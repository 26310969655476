/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import tw, { css, theme } from "twin.macro";
import {
  ArticleGrid,
  Footer,
  Go,
  Grid,
  Layout,
  PageBuilder
} from "~components";
import * as PageBuilderSections from "~components/PageBuilder/sections";
import { useAuth, useTheme } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as Arrow } from "~assets/icons/arrow.svg";
import { ReactComponent as CategoryCommunity } from "~assets/icons/community-icon.svg";
import { ReactComponent as CategoryCultivate } from "~assets/icons/cultivate-icon.svg";
import { ReactComponent as CategoryGrowth } from "~assets/icons/growth-icon.svg";
import { ReactComponent as CategoryReplenish } from "~assets/icons/replenish-icon.svg";
import { ReactComponent as CategoryTheLand } from "~assets/icons/landscape-icon.svg";
import { ReactComponent as CategoryToolkit } from "~assets/icons/toolkit-icon.svg";

const Category = ({ data, location }) => {
  const content = data?.sanityCategory || {};

  let pageBuilderContent = {};

  if (content?.pagebuilder) {
    pageBuilderContent = content.pagebuilder;
  }

  // ===========================================================================
  // context / ref / state

  const { loggedIn } = useAuth();
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const [articles, setArticles] = useState([]);

  // ===========================================================================
  // variables

  const iconCSS = [tw`w-1/2 relative`];

  const icons = {
    community: (
      <CategoryCommunity
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    cultivate: (
      <CategoryCultivate
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    growth: (
      <CategoryGrowth
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    replenish: (
      <CategoryReplenish
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    "the land": (
      <CategoryTheLand
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    ),
    toolkit: (
      <CategoryToolkit
        css={iconCSS}
        fill={lowImpactMode ? colours.background : colours.foreground}
      />
    )
  };

  const { sanityCategory, allSanityArticle } = data;
  const { seoDescription, seoKeywords } = sanityCategory;

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    if (articles?.[0] || !allSanityArticle?.edges?.[0]) {
      return;
    }

    const categoryArticles = allSanityArticle.edges.filter(
      ({ node }) =>
        node?.category?.name?.toLowerCase() ===
        sanityCategory?.name?.toLowerCase()
    );

    setArticles(categoryArticles.map(({ node }) => node));
  }, [allSanityArticle]);

  // ==========================================================================
  // render

  return (
    <>
      <Layout location={location} seo={{ seoDescription, seoKeywords }}>
        <section
          css={[
            css`
              ${colourTransitionCSS};

              background: ${lowImpactMode
                ? colours.foreground
                : colours.background};
              color: ${lowImpactMode ? colours.background : colours.foreground};

              padding-top: 56px;

              ${MEDIA_QUERIES?.desktop} {
                padding-top: 90px;
              }
            `,
            tw`relative z-10`
          ]}
        >
          <div
            css={[
              css`
                animation: 1s ease appear-down forwards;
              `,
              tw`relative pt-8 md:pt-24 pb-8 md:pb-24`
            ]}
          >
            <Grid>
              <div css={[tw`col-span-12 md:col-span-2`]}>
                <Go to={loggedIn ? `/app/dashboard` : `/library`}>
                  <div css={[tw`relative flex items-center`]}>
                    <Arrow
                      css={[tw`w-4 mr-2 md:mr-3 relative block`]}
                      fill="#DEEFCB"
                    />

                    <p css={[tw`mt-1 text-b1 md:text-b1-md font-body`]}>Back</p>
                  </div>
                </Go>
              </div>

              <article css={[tw`col-span-8 md:col-span-5 pt-12 md:pt-0`]}>
                <h1 css={[tw`font-head text-h2 md:text-h2-md`]}>
                  {sanityCategory?.name}
                </h1>

                <p css={[tw`mt-6 font-body text-b2 md:text-h4-md`]}>
                  {sanityCategory?.description}
                </p>
              </article>

              <div
                css={[
                  tw`col-span-4 md:col-span-4 relative flex items-center justify-center`
                ]}
              >
                {icons?.[sanityCategory?.name?.toLowerCase()]}
              </div>
            </Grid>
          </div>
        </section>

        <PageBuilder
          content={pageBuilderContent}
          sections={PageBuilderSections}
        />

        {articles?.[0] && (
          <div
            css={[
              css`
                animation: 1s ease appear-down forwards;
                opacity: 0;
                animation-delay: 0.5s;
              `,
              tw`relative`
            ]}
          >
            <ArticleGrid articles={articles} />
          </div>
        )}

        <Footer />
      </Layout>
    </>
  );
};

export default Category;

export const query = graphql`
  query Category($id: String!) {
    sanityCategory(id: { eq: $id }) {
      slug {
        current
      }

      name
      description

      ...CategoryBuilderFragment

      seoTitle
      seoDescription
      seoKeywords
    }

    allSanityArticle {
      edges {
        node {
          _id

          availability
          title
          excerpt
          tokenKey
          cost
          readTime

          slug {
            current
          }

          theme {
            backgroundColour {
              hex
            }
            foregroundColour {
              hex
            }
            buttonColour
          }

          category {
            name
          }

          type {
            title
          }

          cardImage {
            asset {
              gatsbyImageData(width: 1200, placeholder: NONE)
            }
            altText
          }
        }
      }
    }
  }
`;
